<template>
  <v-container fluid class="pageContainer">
    <v-row
      v-if="!loading"
      align-content="center"
      justify="center"
      style="min-height: 231px;"
    >
      <v-col cols="12">
        <VueSlickCarousel
          v-bind="carrouselSettings"
          ref="uniformsCarousel"
          :arrows="false"
          :variableWidth="uniforms.length > 1"
          @afterChange="updateSelectedUniform"
        >
          <div
            v-for="(uniform, index) in uniforms"
            :key="uniform._id"
            class="uniform-container"
          >
            <v-btn
              v-if="previousSelectedIndex === index && index > 0"
              class="arrowLeftIcon"
              icon
              @click="carouselPrevious()"
            >
              <v-icon class="slideLeftAnimation">
                mdi-chevron-left
              </v-icon>
            </v-btn>

            <inline-svg
              :src="`${uniform.image}?x-request=xhr`"
              :id="`uniform-${index}`"
              class="uniform-image"
            />

            <v-btn
              v-if="previousSelectedIndex === index && index < uniforms.length - 1"
              class="arrowRightIcon"
              icon
              @click="carouselNext()"
            >
              <v-icon class="slideRightAnimation">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </VueSlickCarousel>
      </v-col>

      <v-col cols="12" class="d-flex justify-center pa-0">
        <v-btn text @click="randomUniform()" color="secondary" class="text-btn">
          <v-icon class="mr-2" color="secondary">mdi-shuffle-variant</v-icon>
          Aleatório
        </v-btn>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="d-flex flex-column justify-center">
        <p class="text-secondary">Cores</p>
        <v-row v-if="previousSelectedIndex >= 0" justify="center">
          <v-col
            cols="4"
            v-for="(element, index) in uniforms[previousSelectedIndex].elements"
            :key="`uniform-${previousSelectedIndex}-color-picker-${index}`"
          >
            <div class="d-flex flex-column justify-center align-center">
              <span class="mb-1 element-name">
                {{element.name}}
                <v-tooltip v-if="element.description" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{element.description}}</span>
                </v-tooltip>
              </span>
              <v-menu
                v-if="parsedUniforms[previousSelectedIndex]"
                bottom
                origin="center center"
                transition="scale-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="default-color-picker"
                    :style="`background: ${parsedUniforms[previousSelectedIndex].colors[index]}`"
                    v-bind="attrs"
                    v-on="on"
                    @click="updateSelectedElement(index)"
                  ></div>
                </template>

                <chrome-picker
                  :value="parsedUniforms[previousSelectedIndex].colors[index]"
                  :disableAlpha="true"
                  @input="updateElementColorValue"
                />
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="11">
        <v-btn class="app-btn" color="primary" x-large block @click="sendSelectedUniformToApp()">
          <span>
            Próximo
          </span>
        </v-btn><br>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import VueSlickCarousel from 'vue-slick-carousel'
import Chrome from 'vue-color/src/components/Chrome.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    VueSlickCarousel,
    InlineSvg,
    'chrome-picker': Chrome
  },
  data () {
    return {
      loading: true,
      changedColor: 0,
      uniforms: [],
      parsedUniforms: {},
      loadedDataFromApp: false,
      previousSelectedIndex: -1,
      selectedUniformElement: -1,
      carrouselSettings: {
        infinite: false,
        focusOnSelect: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '0',
        outerEdgeLimit: true
      }
    }
  },
  mounted () {
    this.getUniforms()
  },
  methods: {
    getUniforms () {
      this.loading = true

      this.$http.get('/uniforms')
        .then((res) => {
          const { data } = res.data
          this.uniforms = data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
          setTimeout(() => {
            this.updateSelectedUniform(0)
          }, 500)
        })
    },
    updateSelectedUniform (index) {
      if (this.previousSelectedIndex !== index) {
        document.getElementById(`uniform-${index}`).classList.remove('unscaleAnimation')
        document.getElementById(`uniform-${index}`).classList.add('scaleAnimation')

        if (this.previousSelectedIndex >= 0) {
          document.getElementById(`uniform-${this.previousSelectedIndex}`).classList.remove('scaleAnimation')
          document.getElementById(`uniform-${this.previousSelectedIndex}`).classList.add('unscaleAnimation')
        }
        this.previousSelectedIndex = index
        this.updateSelectedUniformObj()
      }
    },
    updateSelectedUniformObj (colors = []) {
      var defaultColor = '#BABABA'
      var uniform = this.uniforms[this.previousSelectedIndex]
      if (!this.parsedUniforms[this.previousSelectedIndex]) {
        var _colors = colors.length > 0 ? colors : Array.from({ length: uniform.elements.length }, () => (defaultColor))

        this.parsedUniforms[this.previousSelectedIndex] = {
          pattern: uniform._id,
          image: uniform.image,
          elements: uniform.elements,
          colors: _colors
        }

        if (!this.loadedDataFromApp) {
          this.getDataFromApp()
        }
      }
    },
    updateElementColorValue (data) {
      if (this.selectedUniformElement >= 0) {
        this.parsedUniforms[this.previousSelectedIndex].colors[this.selectedUniformElement] = data.hex

        const elementItens = document
          .getElementById(`uniform-${this.previousSelectedIndex}`)
          .getElementsByClassName(this.uniforms[this.previousSelectedIndex].elements[this.selectedUniformElement].class)

        for (const element of elementItens) {
          element.setAttribute('style', `fill: ${data.hex}`)
        }
      }
    },
    updateSelectedElement (index) {
      this.selectedUniformElement = index
    },
    randomUniform () {
      var randomIndex = Math.floor((Math.random() * this.uniforms.length))
      var randomColors = Array.from({ length: this.uniforms[randomIndex].elements.length }, () => this.randomColor())
      this.updateSelectedUniform(randomIndex)
      this.updateSelectedUniformObj(randomColors)
      for (let elementIndex = 0; elementIndex < this.uniforms[randomIndex].elements.length; elementIndex++) {
        this.selectedUniformElement = elementIndex
        this.updateElementColorValue({ hex: randomColors[elementIndex] })
      }

      this.$refs.uniformsCarousel.goTo(randomIndex)
    },
    getDataFromApp () {
      if (this.previousSelectedIndex >= 0 && Object.keys(this.parsedUniforms).length > 0) {
        this.loadedDataFromApp = true
        var self = this
        var uniforms = this.uniforms

        window.flutter_inappwebview
          .callHandler('getSelectedValues', '')
          .then(function (result) {
            for (let index = 0; index < uniforms.length; index++) {
              const uniform = uniforms[index]

              if (uniform._id === result.pattern) {
                self.previousSelectedIndex = index
                self.updateSelectedUniformObj(result.colors)
                self.$refs.uniformsCarousel.goTo(self.previousSelectedIndex)

                for (let elementIndex = 0; elementIndex < uniforms[index].elements.length; elementIndex++) {
                  self.selectedUniformElement = elementIndex
                  self.updateElementColorValue({ hex: result.colors[elementIndex] })
                }
                break
              }
            }
          })
      } else {
        setTimeout(() => {
          this.getDataFromApp()
        }, 1000)
      }
    },
    sendSelectedUniformToApp () {
      var selectedUniform = this.parsedUniforms[this.previousSelectedIndex]
      window.flutter_inappwebview
        .callHandler('saveSelectedValues', selectedUniform)
    },
    carouselNext () {
      this.$refs.uniformsCarousel.next()
    },
    carouselPrevious () {
      this.$refs.uniformsCarousel.prev()
    },
    imageErrorHandler (index) {
      this.uniforms[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.pageContainer {
  background: #F8F8F8;
}

.uniform-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 200px;
}

.uniform-image {
  width: 100%;
  height: 60%;
}

.text-btn {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
}

.text-secondary {
  color: #005E5D;
  font-size: 16px;
  font-weight: bold;
}

.default-color-picker {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 1px solid #a8a8a8;
}

.element-name {
  font-size: 11px
}

.app-btn {
  border-radius: 11px;
}

.app-btn span {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bolder;
}

.arrowLeftIcon, .arrowRightIcon {
  position: absolute;
  z-index: 99;
}

.arrowLeftIcon {
  left: 0;
}

.arrowRightIcon {
  right: 0;
}
</style>
